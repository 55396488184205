<template>
  <div>退出登录</div>
</template>

<script setup>
/* 本页面进用于企业端退出登录使用 */
import { onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
onMounted(() => {
  store.dispatch('user/logout')
})
</script>

<style scoped>

</style>
