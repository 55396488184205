import request from '@/utils/request'

/**
 * 地图找房按城区统计在架房源量
 */

export const getAreaHouse = () => {
  return request({
    url: '/free/statisticsUpShelvesHouse'
  })
}

/**
 * 地图找房按指定城区或小区统计在架房源量
 * communityId 公寓户型ID
 * districtId 查询类型1未出租，2已出租
 */

export const getMapHouse = (data) => {
  return request({
    url: '/free/staticUpShelveHouseById',
    method: 'POST',
    data
  })
}
