<template></template>

<script setup>
import { onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getUserInfoApi } from '@/api/user'
import { useStore } from 'vuex'

const store = useStore()

const router = useRouter()
const route = useRoute()

async function checkToken() {
  // 统一的token信息
  const token = route.query.token
  // 预保留字段，暂时不用
  const mark = route.query.mark
  // 1-要实名认证，2-要上传身份证，3-要重置密码
  const type = route.query.type
  if (!token) {
    router.push('/login')
  }
  // 设置token
  store.commit('user/setToken', token)
  const response = await getUserInfoApi()
  store.commit('user/setUserInfo', response.userInfo)
  if (type == 1) {
    setTimeout(() => {
      router.push('/auth')
    }, 300)
  } else if (type == 2) {
    setTimeout(() => {
      router.push('/cert')
    }, 300)
  } else if (type == 3) {
    setTimeout(() => {
      router.push('/changePwd')
    }, 300)
  } else {
    window.location.href = '/'
  }
}

onMounted(async () => {
  await checkToken()
})
</script>

<style scoped></style>
