<template>
  <Header />
  <div class="authorization">
    <div class="authorization-top">
      <div>
        <img :src="imgUrl" alt="" />
      </div>
      <div>
        <p class="authorization-title">{{ titleName }}<span> 客户端</span></p>
        <p>此第三方应用请求获得以下权限:</p>
      </div>
    </div>
    <div class="authorization-checkbox">
      <el-checkbox v-model="isConsent" label="访问你的个人信息" size="large" />
    </div>
    <div style="margin-top: 10px">
      <button class="button1" @click="addClick">同意授权</button
      ><button class="button2" @click="noClick">拒绝</button>
    </div>
  </div>
  <Footer />
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import router from '../../router/index'
import { ElMessage } from 'element-plus'
import { getInfo, getUserInfo } from '@/api/authorization'
import Header from '../../layout/components/Header.vue'
import Footer from '../../layout/components/Footer.vue'
let imgUrl = ref('') //头像
let titleName = ref('xxxx') //授权名字
let isConsent = true //授权复选框值
let code = ref('')
// 获取code显示用户信息
onBeforeMount(async () => {
  code.value = window.location.href.split('=')[1]
  let result = await getInfo(code.value)
  titleName.value = result.appName
  imgUrl.value = result.appLogo
})
// 同意
async function addClick() {
  // 验证复选框
  if (isConsent) {
    let res = await getUserInfo({
      code: code.value,
      isConsent
    })
    window.location.href = res.clientRedirectUrl + '?result=' + res.userInfo
  } else {
    ElMessage({
      showClose: true,
      message: '请授权访问信息',
      type: 'error'
    })
  }
}
// 拒绝
function noClick() {
  router.push({ path: '/home' })
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.authorization {
  margin: 70px auto;
  width: 600px;
  height: 380px;
}

.authorization img {
  width: 100px;
  height: 100px;
}

.authorization-top {
  display: flex;
}

.authorization-top > div:nth-child(1) {
  margin-right: 20px;
}

.authorization-top > div:nth-child(2) > p:nth-child(1) {
  margin: 15px 0;
  font-size: 24px;
}

.authorization-top > div:nth-child(2) > p:nth-child(2) {
  font-size: 14px;
}

.authorization-checkbox {
  color: rgb(143, 143, 143);
  display: flex;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  font-size: 14px;
}

.authorization button {
  width: 80px;
  height: 30px;
}

.button1 {
  background-color: rgb(64, 158, 255);
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.2s;
}

.authorization .button1:active {
  color: rgb(77, 77, 77);
}

.button2 {
  background-color: white;
  border: 1px solid rgb(136, 136, 136);
  cursor: pointer;
  transition: 0.2s;
  margin-left: 30px;
}

.authorization .button2:active {
  background-color: #ccc;
}

.authorization-bottom {
  margin-top: 20px;
}

.authorization-bottom .btn {
  color: rgb(64, 158, 255);
  cursor: pointer;
}

// .footer-wrapper {
//     position: fixed;
//     bottom: 0;
// }
</style>
