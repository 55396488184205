<template>
  <div class='map-search-warp'>
    <!-- 头部 -->
    <el-row :gutter='20' justify='space-between' style='margin: 10px auto'>
      <el-col class='title' :span='14'>
        <el-col :span='4'>
          <el-image style='width: 51px' :src='logo'>
            <template #error>
              <div class='image-slot'>
                <el-icon>
                  <icon-picture />
                </el-icon>
              </div>
            </template>
          </el-image>
        </el-col>
        <el-col :span='20'>
          <h1>南宁市住房租赁服务监管平台—地图找房</h1>
        </el-col>
      </el-col>
      <el-col class='search-box' :span='8'>
        <el-autocomplete
          style='width: 100%'
          v-model='keyword'
          placeholder='小区关键词搜索'
          :fetch-suggestions='querySearch'
          :trigger-on-focus='false'
          @select='onSelect'
          clearable
        >
          <template #append style='background-color: #409eff'>
            <el-button @click='search' class='search-btn'>
              <el-icon :size='20' color='#fff'>
                <Search />
              </el-icon>
            </el-button>
          </template>
        </el-autocomplete>
      </el-col>
    </el-row>
    <!-- 地图渲染  -->
    <el-row>
      <div class='list-box'>
        <div
          class='list-item'
          v-for='(h, index) in mapHouse'
          :key='index'
          @click='moveMap(h)'
        >
          {{ h.xqmc }}
        </div>
      </div>
      <div
        id='baiduMap'
        :style="{ width: mapWidth + 'px', height: mapHeight + 'px' }"
        @mousewheel.capture="initMap('baiduMap', zoomValue)"
      ></div>
    </el-row>
  </div>
</template>
<script setup>
import { getAreaHouse, getMapHouse } from '@/api/mapSearch'
import { ElMessage } from 'element-plus'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const logo = require('@/assets/logo.png')
// const pointIcon = require('@/assets/point_b.png')
// const pointIconHover = require('@/assets/point_b_h.png')
const mapWidth = ref(0)
const mapHeight = ref(0)
const zoomValue = ref(12)
const areaHouse = ref([])
const mapHouse = ref([])
const router = useRouter()
const keyword = ref('')
/**
 * 加载地图（百度）
 */
let map = {}
// let marker = {}
const BMap = window.BMap
const initMap = async (divId = 'baiduMap', zoom = 12, latitude, longitude) => {
  const lat = latitude || 22.823831
  const lng = longitude || 108.372634
  // 创建点坐标
  const point = new BMap.Point(lng, lat)
  // 初始化地图
  map = new BMap.Map(divId)
  // 初始化地图（设置中心点坐标和地图级别）
  map.centerAndZoom(point, zoom)
  // 移动到中心点坐标
  map.panTo(point)
  // 开启鼠标滚轮缩放
  map.enableScrollWheelZoom(true)
  // // 启用放大缩小尺
  map.addControl(new BMap.NavigationControl())
  // 监听缩放级别
  map.addEventListener('zoomend', () => {
    zoomValue.value = map.getZoom()
    if (zoomValue.value > 12) {
      map.clearOverlays() // 清除地图上所有覆盖物
      getHouseInfo(true)
    } else {
      map.clearOverlays() // 清除地图上所有覆盖物
      getPointInfo()
    }
  })

  // 默认标注拖拽（初始化位置信息）
  await getPointInfo()
  await getHouseInfo()
}
// 获取区域房源定位及数量信息
const getPointInfo = async () => {
  if (areaHouse.value.length === 0) {
    const result = await getAreaHouse()
    areaHouse.value = result
    console.log('区域房源定位及数量信息')
    console.log(result)
  }
  areaHouse.value.forEach((v, idx) => {
    createPoint(v, idx)
  })
}
// 获取小区房源定位及数量信息
const getHouseInfo = async (isShow) => {
  if (mapHouse.value.length === 0) {
    const result = await getMapHouse({
      communityId: '',
      districtId: ''
    })
    mapHouse.value = result
    // console.log('小区房源定位及数量信息')
    // console.log(mapHouse.value)
  }

  if (isShow) {
    mapHouse.value.forEach((v, idx) => {
      createPoint(v, idx, 1)
    })
  }
}
// 设置标注点定位信息（地址,经度,纬度）
const createPoint = async (e, i, t = 0) => {
  const title = t === 0 ? e.cqmc : e.xqmc
  // 创建图标
  // var myIcon = new BMap.Icon(pointIcon, new BMap.Size(120, 53),
  //   { imageOffset: new BMap.Size(0, 0) }) // 设置图片偏移
  // 创建Marker标注，使用图标
  var pt = new BMap.Point(e.gisx, e.gisy)
  // marker = new BMap.Marker(pt, {
  //   icon: myIcon
  // })
  // 设置标签 内容,坐标,偏移量
  const label = new BMap.Label(title + ' | ' + e.sellnum + '套', {
    position: pt,
    offset: new BMap.Size(2, 5)
  })

  // 标签样式
  label.setStyle({
    border: 'none',
    borderRadius: '3px',
    background: 'rgba(63,146,224,1)',
    boxShadow: '0px 0px 5px 1px #5ba4eb inset',
    transform: 'translate(0%,0)',
    fontSize: '14px',
    padding: '5px 10px',
    color: '#fff'
  })
  // 标签鼠标移入、移出、点击事件
  label.addEventListener('mouseover', (e, i) => {
    // 鼠标移入事件
    label.setStyle({
      background: 'rgba(135,4,126,1)',
      cursor: 'pointer',
      boxShadow: '0px 0px 5px 1px #c00ba9 inset'
    }) // 鼠标移入显示窗口
  })
  label.addEventListener('mouseout', (e, i) => {
    label.setStyle({
      background: 'rgba(63,146,224,1)',
      boxShadow: '0px 0px 5px 1px #5ba4eb inset'
    }) // 鼠标隐藏显示窗口
  })
  label.addEventListener('click', (e, i) => {
    if (t === 0) {
      map.panTo(e.point)
      map.setZoom(14)
    } else {
      router.push({
        name: 'houseList',
        params: { projectName: title }
      })

      // window.open(routeUrl.href, '_blank')
    }
  })
  // 将标签添加到地图
  map.addOverlay(label)
}
const moveMap = async (e) => {
  await map.setZoom(19)
  var pt = new BMap.Point(e.gisx, e.gisy)
  map.panTo(pt)
}
/**
 * 自动联词搜索选择事件
 */
const onSelect = (e) => {
  console.log(e)
}

const querySearch = (queryString, cb) => {
  const houseData = []
  mapHouse.value.map((item) => {
    houseData.push({ value: item.xqmc, link: item.xqid })
  })
  console.log(houseData)
  const results = queryString
    ? houseData.filter(createFilter(queryString))
    : houseData
  cb(results)
}
const createFilter = (queryString) => {
  return (item) => {
    return item.value.indexOf(queryString) !== -1
  }
}
const search = () => {
  if (keyword.value) {
    const searchRes = mapHouse.value.filter((item) => {
      return item.xqmc.indexOf(keyword.value) !== -1
    })
    if (searchRes.length > 0) {
      moveMap(searchRes[0])
    } else {
      ElMessage.error('您所查询的小区暂无相关数据')
    }
  } else {
    ElMessage.error('请输入小区关键词')
  }
}
onMounted(() => {
  mapWidth.value = document.documentElement.clientWidth
  mapHeight.value = document.documentElement.clientHeight - 80
  window.setTimeout(function() {
    initMap('baiduMap')
  }, 100)
})
</script>
<style lang='scss'>
.map-search-warp {
  margin: 20px auto;
  width: 100%;
  height: 100%;

  .search-box {
    margin-right: 10px;

    .el-input-group__append {
      background: #409eff;
      border: 1px #409eff solid;
      border-left-width: 0;
    }
  }

  #baiduMap {
    position: relative;
  }

  .list-box {
    position: absolute;
    right: 20px;
    padding: 20px;
    top: 20px;
    z-index: 999;
    max-height: 600px;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 0.8);

    .list-item {
      line-height: 40px;
    }

    .list-item:hover {
      cursor: pointer;
      color: #409eff;
      font-weight: 500;
    }
  }
}
</style>
