import request from '@/utils/request'

/**
 * 获取授权应用信息
 */
export const getInfo = (code) => {
  return request({
    method: 'GET',
    url: `/third/app/oauth/${code}`
  })
}
/**
 * 获取授权用户信息
 */
export const getUserInfo = (params) => {
  return request({
    method: 'GET',
    url: `third/app/userInfo`,
    params
  })
}
